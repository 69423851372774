<template>
  <div>
    <h2>MyForexGlobal Academy</h2>
    
    <div class="card">
      <div class="card-body">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>